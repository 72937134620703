import Lottie from "lottie-react";
import HeartAnimation from "@/utils/lottie/heart-animation.json";

const PageLoader = () => {
  return (
    <div className="fixed top-0 left-0 z-50 w-screen h-screen bg-white bg-opacity-50 flex items-center justify-center">
      <Lottie
        animationData={HeartAnimation}
        loop={true}
        className="-mt-14 w-[140px] h-[140px]"
      />
    </div>
  );
};

export default PageLoader;
